<template>
  <Form
    :submit="onSubmit"
    :initialValues="initialValues"
  >
    <div class="form-narrow">
      <div class="flex items-center justify-end mb-2">
        <toggle-input v-model="useManualIdInput" size="small" />
        <span class="text-black text-sm font-600 font-inter ml-4">manual id input</span>
      </div>
      <div class="form-row">
        <TextField v-if="!initialValues.locationId" name="id" label="id*" :validate="composeValidators(required, uuid)" />
        <div v-else class="form-col">
          <label class="label">id</label>
          <div>{{initialValues.id}}</div>
        </div>
      </div>
      <div class="form-row">
        <div class="w-1/2 pr-2">
          <TextField v-if="useManualIdInput" name="locationId" label="location id*" :validate="composeValidators(required, uuid)" />
          <SelectInput v-else name="locationId" label="location*" :options="locations" :validate="required" no-clear />
        </div>
        <div class="w-1/2 pl-2">
          <TextField v-if="useManualIdInput" name="communityId" label="community id*" :validate="composeValidators(required, uuid)" />
          <SelectInput v-else name="communityId" label="community*" :options="communities" :validate="required" no-clear />
        </div>
      </div>
      <div class="form-row w-1/2 pr-2">
        <SelectInput name="type" label="type*" :options="types" :validate="required" no-clear />
      </div>
      <div class="form-row">
        <div class="w-1/2 pr-2">
          <DateInput
              :validate="required"
              label="start date*"
              name="startDate"
          />
        </div>
        <div class="w-1/2 pl-2">
          <DateInput
              :validate="required"
              label="end date*"
              name="endDate"
          />
        </div>
      </div>
      <ModalFooter :footer="footer" :tertiary="cancel" />
    </div>
  </Form>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Form from "@/components/form/Form";
  import TextField from "@/components/form/TextField";
  import SelectInput from "@/components/form/SelectInput";
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import DateInput from "@/components/form/DateInput";
  import ToggleInput from "@/components/ui/ToggleInput";

  export default {
    name: "LeaseForm",
    components: {
      SelectInput,
      TextField,
      ModalFooter,
      Form,
      DateInput,
      ToggleInput,
    },
    mixins: [
      ModalNavigation,
      NotifyMixin,
      ValidatorMixin,
    ],
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      initialValues: {
        type: Object,
        default: () => ({})
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        types: [
          {
            key: 'contemplated',
            value: 'contemplated'
          },
          {
            key: 'future',
            value: 'future'
          },
          {
            key: 'current',
            value: 'current'
          },
          {
            key: 'terminated',
            value: 'terminated'
          },
        ],
        communities: [],
        locations: [],
        useManualIdInput: false,
        footer: {
          primaryButton: 'save',
          tertiaryButton: 'cancel'
        },
      }
    },
    methods: {
      cancel(e) {
        e.preventDefault();
        this.close();
      },
      fetchLocations() {
        this.$raaDataProvider.getList('locations', {size: 999})
          .then(({content}) => {
            this.locations = content.filter(({deletedAt}) => !deletedAt)
              .map(({id, name }) => ({
                key: id,
                value: name,
              }));
          })
          .catch((err) => this.notifyError(err));
      },
      fetchCommunities() {
        this.$authDataProvider.getList('communities', { size: 999, nonDeleted: true })
            .then(({content}) => {
              this.communities = content.filter(({deletedAt}) => !deletedAt)
                  .sort((a, b) => a.customerName.localeCompare(b.customerName))
                  .map(({communityId: key, communityName, customerName}) => ({
                    key,
                    value: `${communityName} (${customerName})`,
                  }));
            })
            .catch(error => this.notifyError(error.message));
      },
    },
    mounted() {
      Promise.all([
        this.fetchLocations(),
        this.fetchCommunities(),
      ]);
    },
  }
</script>

<style scoped>

</style>
